import React, { useEffect, useState } from "react";

import gsap from "gsap";
import HTMLReactParser from "html-react-parser";

import { Plus } from "../icons";
import { Wrapper, Faq } from "./Faqs.styles";

const FaqC = (props) => {
  //PROPS
  const { dataColor, faq, id, open } = props;
  //PROPS

  //STATES
  const [state, setState] = useState(false);
  //STATES

  //DEFS
  const tl = gsap.timeline();
  //DEFS

  //EFFECTS
  useEffect(() => {
    if (state) {
      tl.to(`#faq${id}`, {
        height: "auto"
      });
    } else {
      tl.to(`#faq${id}`, {
        height: "0"
      });
    }
  }, [state]);

  useEffect(() => {
    setState(open);
  }, [open]);
  //EFFECTS

  return (
    <Faq dataColor={dataColor}>
      <button className="faq-quest" onClick={() => setState(!state)}>
        <h2>{faq.pergunta}</h2>
        <Plus state={state} />
      </button>
      <div className="faq-answer" id={"faq" + id}>
        <div className="faq-answer-inner">
          {faq.resposta && HTMLReactParser(faq.resposta)}
        </div>
      </div>
    </Faq>
  );
};

const Faqs = (props) => {
  //PROPS
  const { data, dataColor } = props;
  //PROPS

  //DEFS
  const tl = gsap.timeline();
  //DEFS

  //STATES
  const [state, setState] = useState(false);
  //STATES

  //EFFECTS
  useEffect(() => {
    if (state) {
      tl.to(`.faq-answer`, {
        height: "auto"
      });
    } else {
      tl.to(`.faq-answer`, {
        height: "0"
      });
    }
  }, [state]);
  //EFFECTS

  return (
    <Wrapper>
      <div className="expand">
        <button
          onClick={() => {
            setState(!state);
          }}
        >
          {state ? "Fechar todos" : "Expandir todos"}
        </button>
      </div>
      <ul>
        {data.map((faq, index) => {
          return (
            <FaqC
              key={index}
              dataColor={dataColor}
              faq={faq}
              id={index}
              open={state}
            />
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default Faqs;
