import styled from "styled-components";

export const Wrapper = styled.div`
  ul {
    list-style: none !important;
    margin: 0 !important;
  }
  .expand {
    display: flex;
    justify-content: flex-end;
    button {
      font-family: "Futura PT Book";
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;
      margin-bottom: 10px;
    }
  }
`;

export const Faq = styled.li`
  background-color: white;
  margin-bottom: 10px;
  .faq-quest {
    padding: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
    h2 {
      font-family: "Futura PT Book" !important;
      text-transform: none !important;
      margin: 0 !important;
      font-weight: bold !important;
      font-size: 18px !important;
      line-height: 23px !important;
      letter-spacing: 0.2px !important;
      color: ${(props) => props.dataColor}!important;
    }
    svg {
      width: 14px;
      height: 14px;
    }
  }
  .faq-answer {
    height: 0;
    overflow: hidden;
    p {
      margin: 0;
      margin: 8px 0;
      color: ${(props) => props.dataColor}!important;
    }
    &-inner {
      padding: 15px;
    }
    li {
      margin: 8px 0;
      color: ${(props) => props.dataColor}!important;
    }
  }
`;
