import React, { useEffect } from "react";

import styled from "styled-components";
import HTMLReactParser from "html-react-parser";

import Faqs from "components/faqs";

import { black } from "theme/colors";
import { breakpoints } from "utils/mixins";

//STYLES
const Wrapper = styled.div`
  height: auto;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.dataColor};
  color: ${(props) => props.dataText};
  &.faq-wrapper {
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    @media ${breakpoints.laptop} {
      padding-top: 60px;
    }
    @media (min-width: 1921px) {
      padding-top: 80px;
    }
    .h2 {
      span {
        text-transform: none;
      }
    }
    h3 {
      text-align: left;
    }
  }

  .container {
    padding: 0 20px;
    margin: 80px auto;
    position: relative;
    h2,
    .h2 {
      margin: 0;
      margin-top: 40px;
      text-align: left;
      font-family: "Futura PT";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;
      text-transform: uppercase;

      color: #398b59;

      color: ${(props) => props.dataText};
    }
    ul {
      margin-left: 30px;
      list-style: disc;
    }
    p,
    li {
      font-family: "Futura PT Book";
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;

      color: ${black.default};

      color: ${(props) => props.dataText};
    }
    a {
      text-decoration: underline;
    }
  }
  @media ${breakpoints.laptop} {
    .container {
      margin: 100px auto;
    }
  }
  @media (min-width: 1921px) {
    .container {
      margin: 100px 15%;
    }
  }
`;
//STYLES

const Policy = (props) => {
  //PROPS
  const {
    dimensions,
    setColor,
    data: {
      page: {
        perguntabanda: { color, introText, faqs }
      }
    }
  } = props;
  //PROPS

  //EFFECTS
  useEffect(() => {
    setColor(false);

    return () => {
      setColor(true);
    };
  }, [dimensions]);
  //EFFECTS

  return (
    <Wrapper dataColor={color} dataText={"white"} className="faq-wrapper">
      <div className="container">
        {introText && HTMLReactParser(introText)}

        <Faqs data={faqs} dataColor={color} />
      </div>
    </Wrapper>
  );
};

export const query = graphql`
  query Pergunta($id: String) {
    page: wpPage(id: { eq: $id }) {
      perguntabanda {
        color
        introText
        faqs {
          pergunta
          resposta
        }
      }
    }
  }
`;

export default Policy;
